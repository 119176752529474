body {
  background-color: aliceblue;
}

.App {
  position: relative;
}

.Content {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.titleContainer {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}

/* .logos {
  display: flex;
  justify-self: flex-end;
  justify-content: space-between;
  align-items: center;
  right: 20px;
  top: 20px;
} */
.logo {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 25px;
}

.pageTitle {
  cursor: pointer;
  border-radius: 10px;
  background-color: rgb(226, 235, 243);
  font-size: large;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  padding: 10px;
}
.pageTitle:hover {
  background-image: linear-gradient(rgba(197, 196, 214, 0.4) 0 0);
}

.input-container {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.inputBar {
  display: flex;
}
.input {
  border: 2px solid black;
  border-right: 0px solid transparent;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px;
}
.input-with-suggestions {
  border-bottom-left-radius: 0px;
}

.searchButton {
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100px;
 cursor: pointer;
 color: #4d66f3;
 background-color: #eff4ff;
 border: 2px solid #4d66f3;
 padding: 10px;
 border-top-right-radius: 10px;
 border-bottom-right-radius: 10px;
}
.searchButton img {
  margin-top: 3px;
  width: 20px;
  height: 20px;
}
.searchButton span {
  font-size: 16px;
  margin-right: 4px;
}
.searchButton:hover {
  background-image: linear-gradient(rgba(195, 192, 233, 0.4) 0 0);
}

.suggestions-container {
  position: relative;
}
.suggestions {
  background-color: aliceblue;
  width: calc(100% - 99px);
  position: absolute;
}
.suggestion {
  cursor: pointer;
  padding: 10px;
  max-width: 100%;
  border: 1px solid black;
  border-top: 0px solid transparent;
  border-bottom: 1px dashed black;
}
.suggestion:hover {
  background-image: linear-gradient(rgba(195, 192, 233, 0.4) 0 0);
}

.results-container {
  margin-top: 18px;
}

.results {
  margin-bottom: 50px;
}

.result {
  padding: 8px;
  padding-top: 0px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-left: 25px;
  margin-right: 25px;
}
.result:hover {
  background-image: linear-gradient(rgba(195, 192, 233, 0.4) 0 0);
}
.result.result-loading:hover {
  background-image: none;
}
.resultHeader {
  padding: 10px;
  display: flex;
}
.resultName {
  font-weight: bold;
}
.resultId {
  font-style: italic;
  margin-left: 10px;
}
.resultEmail {
  background-color: aliceblue;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
  max-width: fit-content;
  border-radius: 10px;
}
.resultContent {
  padding: 8px;
  border-radius: 10px;
  background-color: rgb(228, 232, 236);
}
.resultExperiencesTitle {
  margin-left: 8px;
}
.resultExperiences {
  background-color: aliceblue;
  padding: 8px;
  margin-top: 5px;
  border-radius: 10px;
}

.more-results-button {
  cursor: pointer;
  color: #4d66f3;
  border: 1px solid #4d66f3;
  border-radius: 10px;
  padding: 10px;
  max-width: fit-content;
  margin: 25px auto 50px;
}
.more-results-button:hover {
  background-image: linear-gradient(rgba(195, 192, 233, 0.4) 0 0);
}

.noResults {
  position: relative;
  margin: 25px auto;
  color: #4d66f3;
  background-color: #eff4ff;
  border: 2px solid #4d66f3;
  padding: 10px;
  max-width: fit-content;
  border-radius: 20px;
}
